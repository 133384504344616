<template>
  <b-sidebar id="add-new-category-sidebar" :visible="isAddNewCategorySidebarActive" bg-variant="white"
    sidebar-class="sidebar-lg" shadow backdrop no-header right @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-category-sidebar-active', val)">
    <template #default="{ hide }">
      <div class="
          d-flex
          justify-content-between
          align-items-center
          content-sidebar-header
          px-2
          py-1
        ">
        <h5 class="mb-0">Add Topics</h5>
        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
      </div>
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
          <validation-provider #default="validationContext" name="Topic Name" rules="required">
            <b-form-group label="Topic Name" label-for="Topic Name">
              <b-form-input id="Topic-Name" v-model="category_data.category_name" autofocus
                :state="getValidationState(validationContext)" trim />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider #default="validationContext" name="Sort Category" rules="required">
            <b-form-group label="Sort Category" label-for="Sort Category">
              <b-form-input id="Sort-Category" v-model="category_data.order" autofocus
                :state="getValidationState(validationContext)" trim type="number" placeholder="Enter Numeric Value" />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <br />

          <img style="width:150px; height: 120px" :src="category_data.category_img" />
          <br />
          <br />
          <b-button variant="primary" @click="showgalleryimage">
            <span class="d-none d-sm-inline">Images gallery</span>
          </b-button>
          <b-button style="margin-left: 10px" variant="primary" @click="$refs.refInputEl.click()">
            <input ref="refInputEl" type="file" class="d-none" @input="inputImageRenderer" />
            <span class="d-none d-sm-inline">Upload</span>
            <feather-icon icon="EditIcon" class="d-inline d-sm-none" />
          </b-button>
          <br />
          <br />

          <zape-gallery-model :props_hideShowModel="showImage" @modelClose="modelClose"
            @saveCategoryImage="saveCategoryImage" />

          <img style="width: 150px; height: 120px"
            v-if="category_data.video == null"
            src="https://zape02.s3.ap-southeast-1.amazonaws.com/0.76871584038628660.98656671003321120.19322443436631032.png" alt="" />
          <div style="width: 150px; height:   120px; object-fit: contain" v-if="category_data.video != null && category_data.video.video_uri">
            <b-embed type="video" aspect="4by3" controls :poster="category_data.video.videoThumbnail">
              <source :src="category_data.video.video_uri" type="video/webm" />
              <source :src="category_data.video.video_uri" type="video/x-m4v" />
              <source :src="category_data.video.video_uri" type="video/mp4" />
              <source :src="category_data.video.video_uri" type="video/avi" />
            </b-embed>
          </div>

          <b-button variant="primary" @click="showVideosModel">
            <span class="d-none d-sm-inline">Select</span>
          </b-button>
          <zape-video-model :props_hideShowModel="statusVideosModel" @modelClose="modelVideoClose"
            @saveCategoryVideo="saveCategoryVideo" />


          <div class="d-flex bg-light text-light align-items px-3 py-2"
            style="position: absolute; bottom: 0%; left: 0%; width: 100%">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" type="submit" @click="hide"
              :disabled="submitbutton">
              Add
            </b-button>
            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="button" style="margin-left: 10px"
              variant="outline-secondary" @click="hide">
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormFile,
  BEmbed,
  BCol,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref } from "@vue/composition-api";
import { required, alphaNum, email } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import countries from "../../../../@fake-db/data/other/countries";
import store from "@/store";
import axios from "axios";
import S3 from "aws-s3";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import ZapeGalleryModel from "../../../components/Model/ZapeGalleryModel.vue"
import ZapeVideoModel from "../../../components/Model/ZapeVideoModel.vue"

export default {
  components: {
    ZapeVideoModel,
    ZapeGalleryModel,
    BSidebar,
    BCol,
    BForm,
    BEmbed,
    BFormGroup,
    BFormInput,
    ToastificationContent,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BFormFile,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddNewCategorySidebarActive",
    event: "update:is-add-new-category-sidebar-active",
  },
  props: {
    isAddNewCategorySidebarActive: {
      type: Boolean,
      required: true,
    },
    classId: {
      type: String,
      required: true,
    },
    subjectId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      countries,
      searchQuery: "",
      showImage: false,
      removebutton: true,
      category_data: {
        category_id: "",
        category_name: "",
        category_img:
          "https://t4.ftcdn.net/jpg/02/07/87/79/360_F_207877921_BtG6ZKAVvtLyc5GWpBNEIlIxsffTtWkv.jpg",
        order: "",
        video: null
      },
      table_data: {},
      orginal_name: "",
      post_categroy_data: "",
      statusVideosModel: false
    };
  },
  computed: {
    submitbutton() {
      return !(
        this.category_data.category_name.length > 0 &&
        this.category_data.category_img.length > 0 &&
        this.category_data.order.length > 0
      );
    },
  },
  methods: {
    saveCategoryVideo(categoryVideo) {
      this.category_data.video = categoryVideo;
    },
    showVideosModel() {
      this.statusVideosModel = true;
    },
    modelVideoClose() {
      this.statusVideosModel = false;
    },
    saveCategoryImage(categoryImage) {
      this.category_data.category_img = categoryImage;
    },
    modelClose(modelState) {
      this.showImage = modelState;
    },
    getImages() {
      if (this.searchQuery.trim().length > 0) {
        return this.table_data.filter((item) => {
          if (item.image_name) {
            return this.searchQuery
              .toLowerCase()
              .split(" ")
              .every((v) => item.image_name.toLowerCase().includes(v));
          }
        });
      } else {
        return this.table_data;
      }
    },
    gettabledata: function () {
      axios
        .get(process.env.VUE_APP_API_URL + "/zape-cms/gallery", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("_t"),
          },
        })
        .then((get_response) => {
          this.table_data = get_response.data;
        });
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    resetForm() {
      this.category_data = {
        category_id: null,
        category_name: null,
        category_img:
          "https://t4.ftcdn.net/jpg/02/07/87/79/360_F_207877921_BtG6ZKAVvtLyc5GWpBNEIlIxsffTtWkv.jpg",
        order: null,
      };
      this.searchQuery = "";
      this.showImage = false;
      this.removebutton = true;
      this.validated = false;
    },
    onSubmit() {
      this.category_data.img_name = this.orginal_name["name"];
      this.category_data.class_id = this.classId;
      this.category_data.subject_id = this.subjectId;

      this.post_categroy_data = this.category_data;
      this.$emit("sendcategorydata", this.post_categroy_data);
      this.category_data.category_name = null;
    },
    categoryOrder(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Notification",
          icon: "BellIcon",
          text: "Please enter a numeric value in Sort Category",
          variant,
        },
      });
    },
    showgalleryimage() {
      this.showImage = true;
      this.removebutton = false;
    },
    hideimagegallery() {
      this.showImage = false;
      this.removebutton = true;
    },
    galleryimg(data) {
      this.category_data.category_img = data;
      this.category_data.value = 1;
    },

    inputImageRenderer(input) {
      this.orginal_name = input.target.files[0];
      if (input.target.files && input.target.files[0]) {
        const config = {
          bucketName: "zape02",
          region: "ap-southeast-1",
          accessKeyId: "AKIA2ANCMLDFN2FQFVLZ",
          secretAccessKey: "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",
          s3Url: "https://zape02.s3.ap-southeast-1.amazonaws.com",
          visibility: "public",
        };
        const S3Client = new S3(config);
        const image =
          Math.random().toString() +
          Math.random().toString() +
          Math.random().toString();
        S3Client.uploadFile(input.target.files[0], image)
          .then((data) => (this.category_data.category_img = data.location))
          .catch((err) => console.error(err));
        this.category_data.value = 0;
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
