<template>
    <div>
        <button type="button" class="btn btn-primary" @click="updateS3JsonBtn()" :disabled="loading">
            <b-spinner v-if="loading" small />
            Update JSON
        </button>
    </div>
</template>
<script>
import {
    BButton,
    BFormInput,
    BFormGroup,
    BAlert,
    BSpinner,
    BFormCheckbox,
    BEmbed,
} from "bootstrap-vue";
import axios from "axios";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import * as Helpers from '../../Helpers';

export default {
    components: {
        BButton,
        BFormInput,
        BFormGroup,
        BAlert,
        BSpinner,
        BFormCheckbox,
        ToastificationContent,
        vSelect,
        BEmbed,
    },
    data() {
        return {
            baseVuePath: "@view/src/views/components/themes/",
            baseReactNativePath: "@src/components/themes/",
            vuePath: "@view/src/views/components/themes/",
            reactNativePath: "@src/components/themes/",
            errorMsg: "",
            loading: false,
            alertMessageToast: "",
            record: {
                // default_status: 0,
            },
            addTypes: [
                {
                    label: 'Video',
                    name: 'video',
                },
                {
                    label: 'Image',
                    name: 'image',
                },
            ],
            loadingMedia: false,
        };
    },
    computed: {
        hasError() {
            return this.errorMsg != "";
        },
    },
    watch: {
    },
    methods: {
        showToast(variant) {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: "Notification",
                    icon: "BellIcon",
                    text: this.alertMessageToast,
                    variant,
                },
            });
        },
        clearErrorMsg() {
            this.errorMsg = "";
        },
        resetModal() {
            this.errorMsg = "";
        },
        async updateS3JsonBtn() {
            this.loading = true;
            try {
                const resp = await axios.post(process.env.VUE_APP_API_URL + "/upload-s3/grades",
                    this.record,
                    {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("_t"),
                        },
                    });
                
                /////////////////////
                try {
                    const resp = await axios.post(process.env.VUE_APP_API_URL + "/upload-s3/subjects",
                        this.record,
                        {
                            headers: {
                                Authorization: "Bearer " + localStorage.getItem("_t"),
                            },
                        });
                    
                    /////////////////////
                    try {
                        const resp = await axios.post(process.env.VUE_APP_API_URL + "/upload-s3/topics",
                            this.record,
                            {
                                headers: {
                                    Authorization: "Bearer " + localStorage.getItem("_t"),
                                },
                            });
                        
                        /////////////////////
                        this.alertMessageToast = resp.data.message;
                        this.showToast("success");
                        this.$emit('emitStatusAdded');
                        this.$bvModal.hide("add-ads-management-modal");
                        this.loading = false;
                        /////////////////////

                    } catch (error) {
                        this.errorMsg = Helpers.catchFunction(error);
                        this.loading = false;
                        this.alertMessageToast = this.errorMsg;
                        this.showToast("danger");
                    }
                    /////////////////////

                } catch (error) {
                    this.errorMsg = Helpers.catchFunction(error);
                    this.loading = false;
                    this.alertMessageToast = this.errorMsg;
                    this.showToast("danger");
                }
                /////////////////////

            } catch (error) {
                this.errorMsg = Helpers.catchFunction(error);
                this.loading = false;
                this.alertMessageToast = this.errorMsg;
                this.showToast("danger");
            }
        },
    },
    props: [
    ],
};
</script>
